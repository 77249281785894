import { computed, reactive } from 'vue'
import dayjs from '@/lib/dayjs'
import { timesConvert } from '@/utils/helper'

export const useFullCalendar = () => {
  const calendarRange = reactive({
    start: null,
    end: null,
  }) // 月曆當前時間區間

  const calendarRangeString = computed(() => {
    const format = 'YYYY-MM-DD'
    const { start, end } = calendarRange
    let dayStart, dayEnd
    if (start && end) {
      dayStart = start.format(format)
      dayEnd = end.format(format)
    } else {
      dayStart = dayjs()
        .startOf('month')
        .format(format)
      dayEnd = dayjs()
        .endOf('month')
        .format(format)
    }
    return { dayStart, dayEnd }
  })

  const getCalendarDate = (calendarApi) => {
    const date = dayjs(calendarApi.getDate())
    return {
      start: date.startOf('month').startOf('week'),
      end: date.endOf('month').endOf('week'),
    }
  }

  const formatEventTime = ({ day, timeStart, timeEnd }) => {
    const start = timesConvert(timeStart)
    let end = timesConvert(timeEnd)
    if (end === '24:00') end = '23:59:59'
    day = dayjs(day).format('YYYY-MM-DD')

    const startTime = `${day} ${start}`
    const endTime = `${day} ${end}`
    const isEndSameDay = timeEnd <= 1440
    const isStartSameDay = timeStart <= 1440

    return {
      startTime: isStartSameDay ? startTime : `${day} ${timesConvert(timeStart - 1440)}`,
      endTime: isEndSameDay ? endTime : '23:59:59',

      startPeriod: isStartSameDay ? start : timesConvert(timeStart - 1440),
      endPeriod: isEndSameDay ? end : timesConvert(timeEnd - 1440),
      isEndSameDay,
      isStartSameDay,
      isSameDay: isEndSameDay,
    }
  }

  const formatEventTitle = ({ start, end, title, isEndSameDay, isStartSameDay, date }) => {
    const crossDate = dayjs(date).add(1, 'day').get('date')
    if (end === '23:59:59' || end === '48:00') end = '24:00'
    if (!isStartSameDay && !isEndSameDay) return `${crossDate}日 ${start} - ${crossDate}日 ${end} ${title}`
    else if (!isStartSameDay) return `${crossDate}日 ${start} - ${end} ${title}`
    else if (!isEndSameDay) return `${start} - ${crossDate}日 ${end} ${title}`
    return `${start} - ${end} ${title}`
  }

  // => 創建月曆事件
  const createCalendarEvent = ({
    title,
    day,
    timeStart,
    timeEnd,
    props,
    color,
  }) => {
    const {
      endTime, startTime, startPeriod, endPeriod, isEndSameDay,
      isStartSameDay,
    } = formatEventTime({ day, timeStart, timeEnd })
    const displayTitle = formatEventTitle({
      date: day,
      start: startPeriod,
      end: endPeriod,
      title,
      isEndSameDay,
      isStartSameDay,
    })

    const data = {
      title: displayTitle,
      start: startTime,
      end: endTime,
      display: 'list-item',
      allDay: false,
      extendedProps: { ...props },
      backgroundColor: color,
    }

    return data
  }

  return {
    calendarRange,
    calendarRangeString,
    getCalendarDate,
    createCalendarEvent,
    formatEventTitle,
    formatEventTime,
  }
}
